//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Register',
  data() {
    return {
      form: {
        firstName: '',
        secondName: '',
        email: '',
        phone: '',
        password: '',
      },
    }
  },
  methods: {
    onSave() {
      return {
        ...this.form,
        first_name: this.form.firstName,
        last_name: this.form.secondName,
        password_confirmation: this.form.password,
      }
    },
    clearForm() {
      this.form = {
        firstName: '',
        secondName: '',
        email: '',
        phone: '',
        password: '',
      }
    },
  },
}
