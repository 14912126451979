//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'ModalEditPaymentMethod',
  components: { ProfileModal },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      method: '',
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('paymentMethod'),
      }
    },
  },
  methods: {
    onCancel() {
      this.$refs.modal.onCancel()
    },
    onOpen(value) {
      this.method = value
      this.$refs.modal.onOpen()
    },
    onClear() {},
    onSave() {
      this.$emit('change', this.method)
      this.$refs.modal.onCancel()
    },
  },
}
