//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import { IS_MOBILE } from '~/store/device/type.device'
export default {
  name: 'TicketTableDetailInfo',
  components: { ProfileModal },
  props: {
    ticketStatuses: {
      type: Array,
      default: () => [],
    },
    ticketTypes: {
      type: Array,
      default: () => [],
    },
    ticketPreview: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      item: null,
      order: null,
      orderStatuses: ['New', 'Waiting for approval', 'Accepted', 'Closed', 'Canceled', 'Refunded'],
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  methods: {
    async onOpen(value) {
      this.item = value
      if(this.item.order_number) {
        const response = await this.$api.orders.getOrder(encodeURIComponent(encodeURIComponent(this.item.order_number)))
        this.order = response.data
      }
      this.$refs.modal.onOpen()
    },
    getTypeTicket(type) {
      return this.ticketTypes.find((item) => item.key === type).type
    },
  },
}
