//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductMiniatureItem from '../Miniatures/ProductMiniatureItem.vue'
import ModalOverlay from '~/components/UI/Modal/ModalOverlay.vue'
export default {
  name: 'ProductVariationsModal',
  components: {
    ModalOverlay,
    ProductMiniatureItem,
  },
  data() {
    return {
      modal: false,
      item: {
        title: '',
        items: [],
      },
    }
  },
  methods: {
    onClose() {
      this.modal = false
    },

    onOpen(props) {
      this.modal = true
      this.item = props
    },

    handleRoute() {
      this.onClose()
    },
  },
}
