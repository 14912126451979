//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Rating from '~/components/UI/Rating.vue'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'WatchReviewModal',
  components: { ProfileModal, Rating },
  props: {},
  data() {
    return {
      product: null,
      styleStar: {
        size: 17,
        padding: 30,
      },
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('myReviews'),
      }
    },
  },
  methods: {
    onCancel() {
      this.$refs.modal.onCancel()
    },
    onOpen(product) {
      this.product = product
      this.$refs.modal.onOpen()
    },
  },
}
