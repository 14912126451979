//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import Toggler from '~/components/UI/Toggler.vue'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
import getCode from '~/mixins/getCode'
export default {
  name: 'CompanyAddressModal',
  components: { ProfileModal, Toggler, DropdownSelect },
  mixins: [getCode],
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: this.$t('create'),
      loading: false,
      disabled: false,
      invalid: false,
      touchForm: false,
      errorPhone: false,
    }
  },
  computed: {
    attrs() {
      return {
        title: `${this.title} ${ this.$t('deliveryAddress') }`,
        loading: this.loading,
        disabled: this.disabled || this.errorPhone && this.touchForm,
      }
    },
    getCompanyName() {
      return this.$store.getters.getCompanyName;
    }
  },
  methods: {
    onChange(val, prop) {
      if (prop !== 'is_default') {
        this.rules[prop].$touch()
      }
      if (this.invalid) {
        if (!this.rules.$invalid) {
          this.disabled = false
          this.invalid = false
        }
      }
      this.$emit('input', prop, val)
    },
    onCancel() {
      this.$refs.modal.onCancel()
    },
    onClear() {
      this.rules.$reset()
      this.invalid = false
      this.disabled = false
      this.$emit('clear')
    },
    handleClick(value) {
      this.title = value
      this.$refs.modal.onOpen()
    },
    onFetch(params) {
      if (this.title === 'Create') {
        return this.$api.user.createAddress(params)
      } else if (this.title === 'Edit') {
        return this.$api.user.updateAddress(params, this.form.id)
      }
    },
    onSave() {
      this.loading = true
      this.disabled = true
      this.touchForm = true
      this.rules.$touch()
      if (this.rules.$invalid || this.errorPhone && this.touchForm) {
        this.loading = false
        this.invalid = true
        return
      }
      const params = {
        is_default: this.form.is_default,
        address: {
          ...this.form,
          apartment: this.form.apartment,
          building: this.form.building,
          city: this.form.city,
          code: this.form.code,
          description: this.form.description,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          company_name: this.form.company_name,
          phone:  this.form.phone.replace(/[^+0-9]/g, ''),
          street: this.form.street,
          voivodeship: this.form.voivodeship,
        },
      }
      this.onFetch(params)
        .then(() => {
          this.onCancel()
          this.onClear()
          this.$emit('update')
        })
        .finally(() => {
          this.loading = false
          this.disabled = false
        })
    },

    onRemove() {
      this.loading = true
      this.disabled = true
      return this.$api.user
        .removeAddress(this.form.id)
        .then(() => {
          this.onCancel()
          this.$emit('update')
        })
        .finally(() => {
          this.loading = false
          this.disabled = false
        })
    },
    setPhoneError(value) {
      this.errorPhone = value
    },
  },
}
