//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'ModalEditCashOnDelivery',
  components: { ProfileModal },
  mixins: [validationMixin],
  props: {
    payment: {
      type: String,
      default: '',
    },
    totalPrice: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        companyPay: '',
        clientPay: '',
      },
      submit: false,
      disabled: false,
    }
  },
  validations() {
    const rules = {
      form: {
        clientPay: { required },
      },
    }

    if(this.payment === this.constants.PAYMENT_TYPE_COMBINED_PAYMENT) {
      rules.form.companyPay = { required }
    }

    return rules
  },
  computed: {
    ...mapState({
      constants: state => state.cart.constants,
    }),
    attrs() {
      return {
        title: this.$t('cashOnDelivery'),
        disabled: this.errorClientPay || this.disabled,
      }
    },
    errorClientPay() {
      return Number(this.form.clientPay) + Number(this.form.companyPay) < this.totalPrice
    },
    errorClientPayText() {
      return this.form.clientPay && this.errorClientPay ? [this.$t('errorClientPay')] : null
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if(this.submit) {
          this.$v.form.$touch()
          if (!this.$v.$invalid) {
            this.disabled = false
          } else {
            this.disabled = true
          }
        }
      },
    },
  },
  methods: {
    getExtraCharge() {
      if(this.form.clientPay && this.payment === this.constants.PAYMENT_TYPE_CLIENT_FULL_PAYMENT) {
        return this.form.clientPay - this.totalPrice
      } else if (this.payment === this.constants.PAYMENT_TYPE_COMBINED_PAYMENT) {
        return (Number(this.form.companyPay) + Number(this.form.clientPay)) - this.totalPrice
      }
    },
    onCancel() {
      this.$refs.modalCashOnDelivery.onCancel()
    },
    onOpen(form) {
      this.form.companyPay = form.companyPay
      this.form.clientPay = form.clientPay
      this.$refs.modalCashOnDelivery.onOpen()
      if(this.payment !== this.constants.PAYMENT_TYPE_FULL_PAYMENT) {
        this.submit = true
        this.$v.form.$touch()
        if (this.$v.$invalid) {
          this.disabled = true
          return
        }
        this.disabled = false
      }
    },
    onClear() {},
    onSave() {
      this.submit = true
      this.$v.form.$touch()
      if (this.$v.$invalid) {
        this.disabled = true
        return
      }
      this.$emit('change', this.form)
      this.$refs.modalCashOnDelivery.onCancel()
    },
  },
}
