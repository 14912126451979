//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import { IS_MOBILE } from '~/store/device/type.device'
import getErrorsForm from '~/mixins/getErrorsForm'

export default {
  name: 'CompanyUsersModal',
  components: { ProfileModal },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [getErrorsForm],
  data() {
    return {
      title: this.$t('create'),
      loading: false,
      disabled: false,
      invalid: false,
      touchForm: false,
      errorPhone: false,
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    attrs() {
      return {
        title: `${this.title} ${this.$t('user')}`,
        loading: this.loading,
        disabled: this.disabled || this.errorPhone && this.touchForm,
      }
    },
  },
  methods: {
    onChange(val, prop) {
      this.rules[prop].$touch()
      this.$emit('input', prop, val)
      if (this.invalid) {
        if (!this.rules.$invalid) {
          this.disabled = false
          this.invalid = false
        }
      }
    },
    onCancel() {
      this.$refs.modal.onCancel()
    },
    onClear() {
      this.rules.$reset()
      this.invalid = false
      this.disabled = false
      this.touchForm = false
      this.errorsForm = {}
      this.$emit('clear')
    },
    handleClick(value) {
      this.title = value
      this.$refs.modal.onOpen()
    },
    onFetch(params) {
      if (this.title === this.$t('create')) {
        return this.$api.user.createCompanyUser(params)
      } else if (this.title === this.$t('edit')) {
        return this.$api.user.updateCompanyUser(params, this.form.id)
      }
    },
    onSave() {
      this.touchForm = true
      this.loading = true
      this.disabled = true
      this.rules.$touch()
      if (this.rules.$invalid || this.errorPhone && this.touchForm) {
        this.loading = false
        this.invalid = true
        return
      }
      const params = {
        ...this.form,
        second_name: this.form.last_name,
        phone: this.form.phone.replace(/[^+0-9]/g, ''),
      }
      this.onFetch(params)
        .then(() => {
          this.onCancel()
          this.onClear()
          this.$emit('update')
        })
        .catch((error) => {
          const { response } = error
          this.errorsForm = response.data.errors
        })
        .finally(() => {
          this.loading = false
          this.disabled = false
        })
    },

    onRemove() {
      this.loading = true
      this.disabled = true
      return this.$api.user
        .removeCompanyUser(this.form.id)
        .then(() => {
          this.onCancel()
          this.$emit('update')
        })
        .finally(() => {
          this.loading = false
          this.disabled = false
        })
    },
    setPhoneError(value) {
      this.errorPhone = value
    },
  },
}
