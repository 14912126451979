//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'ModalEditDeliveryMethod',
  components: { ProfileModal },
  props: {
    deliveryMethods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      method: '',
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('deliveryMethod'),
      }
    },
  },
  methods: {
    onCancel() {
      this.$refs.modalDeliveryMethod.onCancel()
    },
    onOpen(value) {
      this.method = value
      this.$refs.modalDeliveryMethod.onOpen()
    },
    onClear() {},
    onSave() {
      this.$emit('change', this.method)
      this.$refs.modalDeliveryMethod.onCancel()
    },
  },
}
