var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('profile-modal',{ref:"modal",staticClass:"order",attrs:{"primary-text-button":_vm.$t('refund'),"title":_vm.$t('orderRefund'),"disabled":_vm.disabled,"width":'828px'},on:{"click:action":_vm.toRefund}},[_c('Table',{staticClass:"order__products scrollbar",attrs:{"fields":_vm.fields,"items":_vm.productsOrder,"table-black":true,"without-lateral-indents":true,"vertical-padding-body-column":'7.5px'},scopedSlots:_vm._u([{key:"cell(main_info)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"order__product-main-info"},[_c('img',{staticClass:"order__product-img",attrs:{"src":((_vm.$config.baseURL) + "/files" + (data.files[0].url)),"alt":"product"}}),_vm._v(" "),_c('div',{staticClass:"order__product-name-wrapper"},[_c('p',{staticClass:"order__product-name"},[_vm._v("\n            "+_vm._s(data.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"order__product-code"},[_c('div',{staticClass:"order__product-code-title"},[_vm._v("\n              "+_vm._s(_vm.$t('code'))+":\n            ")]),_vm._v(" "),_c('div',{staticClass:"order__product-code-number"},[_vm._v("\n              "+_vm._s(data.code)+"\n            ")])]),_vm._v(" "),_c('p',{staticClass:"order__product-set-name"},[_vm._v("\n            "+_vm._s(data.set_name)+"\n          ")])])])]}},{key:"cell(quantity)",fn:function(ref){
var data = ref.data;
return [_vm._v("\n      "+_vm._s(data)+"\n    ")]}},{key:"cell(id)",fn:function(ref){
var data = ref.data;
return [_c('counter',{attrs:{"height":'32px',"width":'30px',"width-count":'50px',"font-size":'17px',"value":_vm.productsOrder[data].to_refund,"disabled-dec":_vm.productsOrder[data].to_refund === 0,"disabled-inc":_vm.productsOrder[data].quantity === _vm.productsOrder[data].to_refund},on:{"click:dec":function($event){return _vm.decreaseItemToRefund(data)},"click:inc":function($event){return _vm.increaseItemToRefund(data)}}})]}},{key:"cell(sum_brutto)",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"order__product-sum"},[_vm._v("\n        "+_vm._s(data)+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }