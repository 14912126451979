//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'ModalEditPaymentMethod',
  components: { ProfileModal },
  props: {
    orderType: {
      type: String,
      default: '',
    },
    paymentMethods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentType: '',
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('payment'),
      }
    },
  },
  methods: {
    onCancel() {
      this.$refs.modalPayment.onCancel()
    },
    onOpen(value) {
      this.paymentType = value
      this.$refs.modalPayment.onOpen()
    },
    onClear() {},
    onSave() {
      this.$emit('change', this.paymentType)
      this.$refs.modalPayment.onCancel()
    },
  },
}
