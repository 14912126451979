//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import PasswordInput from '~/components/UI/PasswordInput.vue'
import { FETCH_USER } from '~/store/user/types.user'
import getErrorsForm from '~/mixins/getErrorsForm'
export default {
  name: 'UpdateUserFormModal',
  components: { ProfileModal, PasswordInput },
  mixins: [validationMixin, getErrorsForm],
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      type: 'contacts',
      title: this.$t('editCredentials'),
      loading: false,
      disabled: false,
      invalid: false,
      touchForm: false,
      errorPhone: false,
    }
  },
  computed: {
    attrs() {
      return {
        title: this.title,
        loading: this.loading,
        disabled: this.disabled || this.errorPhone && this.touchForm,
      }
    },
  },
  methods: {
    onChange(val, prop) {
      this.$emit('input', prop, val)
      if (this.invalid) {
        if (!this.rules.$invalid) {
          this.disabled = false
          this.invalid = false
        }
      }
    },

    handleClick(value) {
      this.type = value.type
      this.title = value.title
      this.$refs.modal.onOpen()
    },
    onCancel() {
      this.$refs.modal.onCancel()
    },
    onClear() {
      this.rules.$reset()
      this.invalid = false
      this.disabled = false
      this.$emit('clear')
    },
    onSend() {
      this.touchForm = true
      this.disabled = true
      this.loading = true
      this.rules.$touch()
      if (this.rules.$invalid || this.errorPhone && this.touchForm) {
        this.loading = false
        this.invalid = true
        return
      }
      switch (this.type) {
      case 'contacts':
        this.onSaveContacts()
        break
      case 'password':
        this.onChangePassword()
        break

      default:
        break
      }
    },
    onSaveContacts() {
      const params = {
        ...this.form,
        phone: this.form.phone.replace(/[^+0-9]/g, ''),
      }
      return this.$api.user
        .updateUser(params)
        .then(() => {
          this.$store.dispatch(`user/${FETCH_USER}`)
          this.onCancel()
          this.touchForm = false
        })
        .catch((error) => {
          const { response } = error
          this.errorsForm = response.data.errors
        })
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    },
    onChangePassword() {
      const params = {
        old_password: this.form.old_password,
        new_password: this.form.new_password,
      }
      return this.$api.user
        .updatePassword(params)
        .then(() => {
          this.onCancel()
        })
        .catch((error) => {
          const { response } = error
          this.errorsForm = response.data.errors
        })
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    },
    setPhoneError(value) {
      this.errorPhone = value
    },
  },
}
