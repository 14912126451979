var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('profile-modal',_vm._b({ref:"modalPayment",staticClass:"modal",on:{"click:action":_vm.onSave,"close":_vm.onClear}},'profile-modal',_vm.attrs,false),[(_vm.orderType === 'Standard')?_c('div',{staticClass:"checkout__process-payment-standard"},_vm._l((_vm.paymentMethods),function(method,key){return _c('div',{key:key,class:[
        'checkout__radio-button',
        { 'checkout__radio-button--active': _vm.paymentType === method.name }
      ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentType),expression:"paymentType"}],attrs:{"id":("paymentMethod-" + key),"type":"radio","name":"delivery-method","checked":""},domProps:{"value":method.name,"checked":_vm._q(_vm.paymentType,method.name)},on:{"change":function($event){_vm.paymentType=method.name}}}),_vm._v(" "),_c('label',{staticClass:"checkout__radio-button-label",attrs:{"for":("paymentMethod-" + key)}},[_c('span',{staticClass:"checkout__radio-button-title"},[_vm._v(_vm._s(method.name))]),_vm._v(" "),_c('span',{staticClass:"checkout__radio-button-subtitle"},[_vm._v(_vm._s(method.value)+" zl")])])])}),0):_vm._e(),_vm._v(" "),(_vm.orderType === 'Dropshipping')?_c('div',{staticClass:"checkout__process-payment-dropshipping"},[_c('div',{class:[
        'checkout__radio-button',
        { 'checkout__radio-button--active': _vm.paymentType === 'Full payment' }
      ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentType),expression:"paymentType"}],attrs:{"id":"fullPayment","type":"radio","value":"Full payment","name":"full-payment","checked":""},domProps:{"checked":_vm._q(_vm.paymentType,"Full payment")},on:{"change":function($event){_vm.paymentType="Full payment"}}}),_vm._v(" "),_c('label',{staticClass:"checkout__radio-button-label",attrs:{"for":"fullPayment"}},[_c('span',{staticClass:"checkout__radio-button-title"},[_vm._v(_vm._s(_vm.$t('fullPayment')))])])]),_vm._v(" "),_c('div',{class:[
        'checkout__radio-button',
        { 'checkout__radio-button--active': _vm.paymentType === 'Client full payment' }
      ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentType),expression:"paymentType"}],attrs:{"id":"clientFullPayment","type":"radio","value":"Client full payment","name":"client-full-payment"},domProps:{"checked":_vm._q(_vm.paymentType,"Client full payment")},on:{"change":function($event){_vm.paymentType="Client full payment"}}}),_vm._v(" "),_c('label',{staticClass:"checkout__radio-button-label",attrs:{"for":"clientFullPayment"}},[_c('span',{staticClass:"checkout__radio-button-title"},[_vm._v(_vm._s(_vm.$t('clientFullPayment')))])])]),_vm._v(" "),_c('div',{class:[
        'checkout__radio-button',
        { 'checkout__radio-button--active': _vm.paymentType === 'Combined payment' }
      ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentType),expression:"paymentType"}],attrs:{"id":"combinedPayment","type":"radio","value":"Combined payment","name":"combined-payment"},domProps:{"checked":_vm._q(_vm.paymentType,"Combined payment")},on:{"change":function($event){_vm.paymentType="Combined payment"}}}),_vm._v(" "),_c('label',{staticClass:"checkout__radio-button-label",attrs:{"for":"combinedPayment"}},[_c('span',{staticClass:"checkout__radio-button-title"},[_vm._v(_vm._s(_vm.$t('combinedPayment')))])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }