//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'ModalEditComment',
  components: { ProfileModal },
  props: {},
  data() {
    return {
      comment: '',
    }
  },
  computed: {
    attrs() {
      return {
        title: this.$t('comment'),
      }
    },
  },
  methods: {
    onCancel() {
      this.$refs.modalComment.onCancel()
    },
    onOpen(value) {
      this.comment = value
      this.$refs.modalComment.onOpen()
    },
    onClear() {},
    onSave() {
      this.$emit('change', this.comment)
      this.$refs.modalComment.onCancel()
    },
  },
}
