//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import Counter from '~/components/UI/Counter.vue'
import Table from '~/components/UI/Table.vue'
import { IS_MOBILE } from '~/store/device/type.device'
import getDeviceType from '~/mixins/getDeviceType'
export default {
  name: 'ModalOrderRefunds',
  components: { ProfileModal, Table, Counter },
  mixins: [getDeviceType],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disabled: false,
      productsOrder: [],
    }
  },
  created() {
    this.productsOrder = this.products.map((item, key) => ({
      ...item,
      id: key,
      reasonProductId: key,
      to_refund: 0,
      refundSum: 0,
      reason: '',
      main_info: {
        files: item.files,
        name: item.name,
        code: item.code,
        set_name: item.set_name,
      }
    }))
  },
  watch: {
    productsOrder: {
      deep: true,
      handler() {
        if(this.productsOrder.some(item => item.to_refund !== 0)) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      },
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    fields() {
      return [
        {
          key: 'main_info',
          label: this.$t('product'),
          width: this.isMobile ? 'auto' : '363px',
        },
        {
          key: 'quantity',
          label: this.$t('qty'),
          width: this.isTablet ? 'auto' : '99px',
        },
        {
          key: 'id',
          label: this.$t('toRefund'),
          width: this.isMobile ? '140px' : '161px',
        },
        {
          key: 'refundSum',
          label: this.$t('refundSum'),
          width: this.isTablet ? 'auto' : '84px',
        },
      ]
    }
  },
  methods: {
    ...mapMutations({
      SET_REFUND_PRODUCTS: 'refund/SET_REFUND_PRODUCTS',
    }),
    async onOpen() {
      this.$refs.modal.onOpen()
    },
    decreaseItemToRefund(i) {
      const set_name = this.productsOrder[i].set_name
      if(set_name) {
        this.productsOrder.forEach((item, key) => {
          if (item.set_name === set_name) {
            this.productsOrder[key].to_refund--
            this.productsOrder[key].refundSum = this.productsOrder[key].refundSum - this.productsOrder[key].price_brutto
          }
        })
      } else {
        this.productsOrder[i].to_refund--
        this.productsOrder[i].refundSum = this.productsOrder[i].refundSum - this.productsOrder[i].price_brutto
      }
    },
    increaseItemToRefund(i) {
      const set_name = this.productsOrder[i].set_name
      if(this.productsOrder[i].to_refund < this.productsOrder[i].quantity) {
        if(set_name) {
          this.productsOrder.forEach((item, key) => {
            if (item.set_name === set_name) {
              this.productsOrder[key].to_refund++
              this.productsOrder[key].refundSum = this.productsOrder[key].to_refund * this.productsOrder[key].price_brutto
            }
          })
        } else {
          this.productsOrder[i].to_refund++
          this.productsOrder[i].refundSum = this.productsOrder[i].to_refund * this.productsOrder[i].price_brutto
        }
      }
    },
    toRefund() {
      const products = this.productsOrder.filter(i => i.to_refund !== 0)
      this.$store.commit('refund/SET_REFUND_PRODUCTS', products)
      this.$router.push({ path: `/profile/orders/${encodeURIComponent(this.$route.params.order)}/refund` })
    },
  },
}
