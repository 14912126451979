//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Rating from '~/components/UI/Rating.vue'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
export default {
  name: 'AddReviewModal',
  components: { ProfileModal, Rating },
  mixins: [validationMixin],
  props: {},
  data() {
    return {
      product: null,
      loading: false,
      disabled: false,
      requiredRating: false,
      form: {
        stars: 0,
        description: '',
        pros: '',
        cons: '',
      },
      styleStar: {
        size: 17,
        padding: 30,
        cursor: 'pointer',
      },
    }
  },
  validations: {
    form: {
      description: { required },
    },
  },
  computed: {
    attrs() {
      return {
        title: this.$t('newReview'),
        primaryTextButton: this.$t('postFeedback'),
        loading: this.loading,
        disabled: this.disabled,
      }
    },
  },
  watch: {
    'form.stars': {
      handler(value) {
        if(value) {
          this.requiredRating = !value
        }
      },
    },
    form: {
      deep: true,
      handler() {
        if(!this.$v.form.$invalid && !this.requiredRating) {
          this.disabled = false
        }
      },
    },
  },
  methods: {
    async onSave() {
      this.requiredRating = !this.form.stars
      this.$v.form.$touch()
      if (!this.$v.form.$invalid && !this.requiredRating) {
        this.disabled = true
        this.loading = true
        const payload = {
          ...this.form,
        }
        for (const propName in payload) {
          if (payload[propName] === '') {
            delete payload[propName]
          }
        }

        try {
          await this.$api.reviews.addProductReview(this.product.entity, payload, this.product.slug)
          this.onCancel()
          this.onClear()
          this.disabled = false
          this.loading = false
          this.$emit('updateReviewsList')
        } catch (error) {
          // console.log(error)
          this.disabled = false
          this.loading = false
        }
      } else {
        this.loading = false
        this.disabled = true
      }
    },
    onClear() {
      this.$v.form.$reset()
      this.requiredRating = false
      this.form = {
        stars: 0,
        description: '',
        pros: '',
        cons: '',
      }
    },
    onCancel() {
      this.onClear()
      this.$refs.modal.onCancel()
    },
    onOpen(product) {
      this.product = product
      this.$refs.modal.onOpen()
    },
    selectRating(n) {
      this.form.stars = n
    },
  },
}
