//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import imageCompression from 'browser-image-compression'
import ModalOverlay from '~/components/UI/Modal/ModalOverlay.vue'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
import { IS_MOBILE } from '~/store/device/type.device'
export default {
  name: 'AddTicketModal',
  components: { ProfileModal, DropdownSelect, ModalOverlay },
  mixins: [validationMixin],
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      message: '',
      loading: false,
      disabled: false,
      invalid: false,
      imageUrl: null,
      image: null,
      image_error: '',
      modal: false,
      submit: false,
      form: {
        type: '',
        first_name: '',
        order_number: '',
        refund_number: '',
        description: '',
        file_id: '',
      },
    }
  },
  validations() {
    const rules = {
      form: {
        type: { required },
        first_name: { required },
        description: { required, minLength: minLength(2), maxLength: maxLength(120) },
      },
    }

    if(this.form.type && this.form.type.key === 4) {
      rules.form.refund_number = { required }
    } else if(this.form.type && this.form.type.key === 3) {
      rules.form.order_number = { required }
    }

    return rules
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    attrs() {
      return {
        loading: this.loading,
        disabled: this.disabled,
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if(this.submit) {
          this.$v.form.$touch()
          if (!this.$v.$invalid) {
            this.disabled = false
          } else {
            this.disabled = true
          }
        }
      },
    },
  },
  methods: {
    clearForm() {
      this.form.first_name = ''
      this.form.order_number = ''
      this.form.refund_number = ''
      this.form.description = ''
      this.form.file_id = ''
    },

    makeToast(variant = null) {
      const h = this.$createElement
      this.message.forEach(message => {
        const vNodesMsg = h(
          'p',
          [ message ]
        )

        this.$bvToast.toast([vNodesMsg], {
          autoHideDelay: 3000,
          variant,
          solid: true,
        })
      })
    },
    onCancel() {
      this.$refs.modal.onCancel()
    },
    onClear() {
      this.submit = false
      this.loading = false
      this.invalid = false
      this.disabled = false
      this.$v.form.$reset()
      this.form = {
        type: '',
        first_name: '',
        order_number: '',
        refund_number: '',
        description: '',
        file_id: '',
      }
      this.form.file_id = ''
      this.image = null
      this.imageUrl = null
    },
    handleClick() {
      this.$refs.modal.onOpen()
    },
    async onSave() {
      this.submit = true
      this.loading = true
      this.disabled = true
      this.$v.form.$touch()
      if (this.$v.$invalid) {
        this.loading = false
        this.invalid = true
        return
      }
      const params = {
        ...this.form,
        type: this.form.type.key,
      }

      for (const propName in params) {
        if (params[propName] === '') {
          delete params[propName]
        }
      }

      try {
        await this.$api.tickets.addTicket(params)
        this.onClear()
        this.onCancel()
        this.$emit('update')
      } catch (error) {
        this.message = ''
        this.loading = false
        this.disabled = false

        this.message = Object.values(error.response.data.errors);
        this.makeToast('danger')
      }
    },
    onImageUpload(e) {
      if (e.target.files.length !== 0) {
        this.imageUrl = URL.createObjectURL(e.target.files[0])
        const reader = new FileReader()
        this.compressed(e.target.files[0])
          .then(file => {
            reader.readAsDataURL(e.target.files[0])
            this.image = file
            this.uploadImage(file)
          })
          // .catch(errors => console.log(errors))
      }
    },
    async uploadImage(file) {
      const fileArr = [file]
      const formData = new FormData()
      fileArr.forEach((el, idx) => {
        formData.append(`files[${idx}]`, el)
      })
      const response = await this.$api.main.addImage(formData)
      this.form.file_id = response.data[0].id
    },
    compressed(file) {
      const options = {
        maxSizeMB: 200,
        fileType: 'image/gif' || 'image/jpeg' || 'image/png' || 'image/bmp' || 'image/tiff',
      }

      return new Promise(resolve => {
        imageCompression(file, options)
          .then(compressedFile => {
            resolve(compressedFile)
          })
          .catch(error => {
            this.image_error = error.message
          })
      })
    },
    nameImage(name) {
      return `${name.slice(0, 22)}.${name.split('.')[1]}`
    },
    openModalImage() {
      this.modal = true
    },
    closeModalImage() {
      this.modal = false
    },
    deleteModalImage() {
      this.form.file_id = ''
      this.image = null
      this.imageUrl = null
    },
  },
}
