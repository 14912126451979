//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, numeric, email } from 'vuelidate/lib/validators'
import ProfileModal from '~/components/UI/Modal/ProfileModal.vue'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
import validationWatcher from '~/mixins/validationWatcher'
import getCode from '~/mixins/getCode'
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)
export default {
  name: 'ModalDeliveryAddress',
  components: {
    ProfileModal,
    DropdownSelect,
  },
  mixins: [validationMixin, getCode, validationWatcher],
  props: {
    orderType: {
      type: String,
      default: '',
    },
    deliveryAddressesNames: {
      type: Array,
      default: () => [],
    },
    deliveryAddresses: {
      type: Array,
      default: () => [],
    },
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        code: '',
        city: '',
        phone: '',
        street: '',
        building: '',
        apartment: '',
        voivodeship: '',
        nip: '',
        company_name: '',
        description: '',
        deliveryAddress: '',
      },
      addressDoesNotExist: false,
      deliveryAddressId: null,
      loadingBtnAsNewDeliveryAddress: false,
      loadingBtnOverwriteCurrent: false,
      submit: false,
      errorPhone: false,
    }
  },
  validations() {
    const rules = {
      is_default: false,
      form: {
        building: { required, maxLength: maxLength(10) },
        apartment: { maxLength: maxLength(10) },
        city: { required, minLength: minLength(2), maxLength: maxLength(100) },
        code: { required },
        first_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        last_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        street: { required, minLength: minLength(2), maxLength: maxLength(100) },
        voivodeship: { required, minLength: minLength(2), maxLength: maxLength(100) },
        email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
        description: { required },
        phone: { required, minLength: minLength(10) },
      },
    }

    if(!this.deliveryAddressId) {
      rules.form.nip = { required, numeric, minLength: minLength(10), maxLength: maxLength(10) }
      rules.form.deliveryAddress = { required }
    }
    return rules
  },
  computed: {
    attrs() {
      return {
        title: this.$t('deliveryAddress'),
        disabled: this.disabled || this.addressDoesNotExist
      }
    },
  },
  watch: {
    'form.deliveryAddress': {
      handler(value) {
        this.fillFormDeliveryAddress(value)
      },
    },
    'form.code': {
      handler() {
        this.getCode()
      },
    },
  },
  methods: {
    async saveOverwriteCurrent() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      const payload = {
        is_default: this.is_default,
        address: {
          ...this.form,
          phone: this.form.phone.replace(/[^+0-9]/g, ''),
          city: this.form.city,
          voivodeship: this.form.voivodeship,
          street: this.form.street,
        },
      }

      for (const propName in payload.address) {
        if (payload.address[propName] === '' || payload.address[propName] === null) {
          delete payload.address[propName]
        }
      }

      try {
        this.loadingBtnOverwriteCurrent = true
        await this.$api.user.updateAddress(payload, this.deliveryAddressId)
        this.setValidationSettings(false)
        this.loadingBtnOverwriteCurrent = false
      } catch (error) {
        this.loadingBtnOverwriteCurrent = false
        // console.log(error)
      }
    },
    async saveAsNewDeliveryAddress() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return

      const payload = {
        is_default: this.is_default,
        address: {
          ...this.form,
          phone: this.form.phone.replace(/[^+0-9]/g, ''),
          city: this.form.city,
          voivodeship: this.form.voivodeship,
          street: this.form.street,
        },
      }

      for (const propName in payload.address) {
        if (payload.address[propName] === '' || payload.address[propName] === null) {
          delete payload.address[propName]
        }
      }

      delete payload.address.deliveryAddress

      try {
        this.loadingBtnAsNewDeliveryAddress = true
        await this.$api.user.createAddress(payload)
        this.setValidationSettings(false)
        this.loadingBtnAsNewDeliveryAddress = false
      } catch (error) {
        this.loadingBtnAsNewDeliveryAddress = false
        // console.log(error)
      }

      if(this.addressDoesNotExist) {
        this.getDeliveryAddresses()
      }
    },
    fillFormDeliveryAddress(description) {
      const address = this.deliveryAddresses.find((address) => address.description === description)
      if(address) {
        this.form.nip = this.companyInfo.nip
        this.deliveryAddressId = address.id
        this.is_default = address.is_default
        this.form.company_name = this.companyInfo.name
        this.form.first_name = address.first_name
        this.form.second_name = address.last_name
        this.form.email = this.companyInfo.email
        this.form.code = address.code
        this.form.city = address.city
        this.form.phone = address.phone
        this.form.street = address.street
        this.form.building = address.building
        this.form.apartment = address.apartment
        this.form.voivodeship = address.voivodeship
        this.form.description = address.description
        this.addressDoesNotExist = false
      } else {
        this.form.description = this.form.deliveryAddress.type
        this.addressDoesNotExist = true
      }
    },
    clearForm() {
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        code: '',
        city: '',
        phone: '',
        street: '',
        building: '',
        apartment: '',
        voivodeship: '',
        nip: '',
        company_name: '',
        description: '',
        deliveryAddress: '',
      }
    },
    onCancel() {
      this.$refs.modalDeliveryAddress.onCancel()
    },
    onOpen(form, deliveryAddressId) {
      this.form = JSON.parse(JSON.stringify(form))
      this.deliveryAddressId = deliveryAddressId
      this.$refs.modalDeliveryAddress.onOpen()
    },
    onClear() {},
    onSave() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      this.setValidationSettings(false)
      this.$emit('change', this.form)
      this.$refs.modalDeliveryAddress.onCancel()
    },
    async getDeliveryAddresses() {
      try {
        const res = await this.$api.user.getCompanyDeliveryAddresses()
        this.deliveryAddresses = res.data
        this.fillFormDeliveryAddress(this.form.description)
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
